"use client"

import * as React from "react"
import { usePathname } from "next/navigation"
import type { Content } from "@prismicio/client"
import { PrismicNextLink } from "@prismicio/next"

import { cn } from "@/lib/utils"
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu"

interface PropsIf {
  settings: Content.SettingsDocument
  partition?: boolean
}

export function NavMenu({ settings, partition }: PropsIf) {
  const pathname = usePathname()

  return (
    <NavigationMenu>
      <NavigationMenuList className="flex-wrap">
        {settings.data.slices1.map((item) => {
          if (item.primary.hide_for_partitioned_dealers && partition) {
            return null
          }

          if (item.primary.show_only_partitioned_dealers && !partition) {
            return null
          }
          
          return (
            <React.Fragment key={item.id}>
              {item.items.length > 0 ? (
                <NavigationMenuItem>
                  <NavigationMenuTrigger>
                    {item.primary.label}
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <ul className="flex flex-col w-[450px]   ">
                      {item.items.map((subItem, i) => {
                        return (
                          <li key={item.id + "subItem" + i} className={'hover:bg-gray-500/10 px-4 py-2'}>
                            <NavigationMenuLink asChild>
                              <PrismicNextLink
                                className={cn(
                                  "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                                )}
                                field={subItem.sub_nav_link}
                              >
                                <h4 className="text-sm font-medium leading-none">
                                  {subItem.sub_nav_label}
                                </h4>
                                <p className="text-sm leading-snug line-clamp-2 text-muted-foreground">
                                  {subItem.sub_nav_copy}
                                </p>
                              </PrismicNextLink>
                            </NavigationMenuLink>
                          </li>
                        )
                      })}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              ) : (
                <NavigationMenuItem>
                  <NavigationMenuLink
                    className={cn(
                      navigationMenuTriggerStyle(),
                      (item.primary.link as any).url.includes(pathname) &&
                        pathname !== "/"
                        ? "font-semibold text-carbon-900"
                        : ""
                    )}
                    asChild
                  >
                    <PrismicNextLink field={item.primary.link}>
                      {item.primary.label}
                    </PrismicNextLink>
                  </NavigationMenuLink>
                </NavigationMenuItem>
              )}
            </React.Fragment>
          )
        })}
      </NavigationMenuList>
    </NavigationMenu>
  )
}
