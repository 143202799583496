"use client"

import { useEffect } from "react"

import { setCurrentDealerId } from "@/lib/cookies.client"

export default function SetDealerCookie({ dealer_id }: { dealer_id: number }) {
  useEffect(() => {
    setCurrentDealerId(dealer_id)
  }, [])
  return <></>
}
